import React from 'react';
import { Box, Image, Text, Heading, VStack, Container, SimpleGrid } from '@chakra-ui/react';

const FounderCard = ({ name, role, imageUrl, bio, quote }) => (
  <VStack
    spacing={4}
    align="center"
    p={6}
    bg="white"
    boxShadow="md"
    borderRadius="lg"
    maxW="400px"
    m="auto"
  >
    <Image
      src={imageUrl}
      alt={name}
      borderRadius="full"
      boxSize="150px"
      objectFit="cover"
      mb={4}
    />
    <Heading as="h3" size="lg" mb={2}>
      {name}
    </Heading>
    <Text fontWeight="bold" color="gray.600">
      {role}
    </Text>
    <Text textAlign="center" color="gray.700">
      {bio}
    </Text>
    <Text fontStyle="italic" color="purple.500" mt={4}>
      "{quote}"
    </Text>
  </VStack>
);

const FoundersSection = () => (
  <Box as="section" py={20} bg="gray.50" id="founders">
    <Container maxW="container.xl">
      <Heading as="h2" size="xl" textAlign="center" mb={12} color="gray.800">
        The Minds Behind Sole Business Solutions
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <FounderCard
          name="Kim Bennett"
          role="Co-Founder"
          imageUrl="kim-profile.jpeg"
          bio="With over three decades of transformative experience in the beauty industry, Kim Bennett is a visionary leader dedicated to empowering independent beauty professionals. As a National Educator and Corporate Culture Ambassador for global beauty brands, Kim has pioneered the salon suite business model, reshaping how beauty professionals operate and thrive while still enjoying servicing her guests successfully for over 20 years!  Her accolades include multiple industry awards, and her expertise is widely recognized in prestigious publications and podcasts, where she shares her insights and passion for innovation. Kim believes in integrating AI technology with personalized business coaching, ensuring that indepenedent beauty professionals have the tools and support they need to flourish in an ever-evolving landscape. Her commitment to mentorship and education continues to inspire countless professionals, shaping the future of the beauty industry."
          quote="Our mission is to empower beauty professionals with the tools they need to thrive in today's digital landscape."
        />
        <FounderCard
          name="Michael Sandoval"
          role="Co-Founder"
          imageUrl="mikel-profile.jpg"
          bio="Michael Sandoval is a visionary in the hair and beauty industry, redefining how salon professionals achieve success. With over 45 years of experience, he is a celebrated designer, international platform artist, and business coach, empowering independent stylists and salon owners to maximize their earning potential. His expertise spans salon management, retail sales, and innovative industry strategies, blending time-tested business principles with cutting-edge technology. As a former international trainer, Michael has inspired stylists worldwide. Today, he leads salon business coaching, leveraging AI-driven solutions, advanced education, and content development to help professionals scale their income and work smarter. A two-time Salon Today 100 award winner, Michael has owned both a boutique salon and a 20-chair commission-based salon. Now, as a salon suite owner and business coach, he equips salon entrepreneurs with the tools and technology to thrive in today’s competitive beauty industry."
          quote="We're combining cutting-edge technology with deep industry expertise to revolutionize the beauty business."
        />
      </SimpleGrid>
    </Container>
  </Box>
);

export default FoundersSection;
